.wallet-card-item img{
    width: 100%;
    object-fit: cover;
    height: 150px;
    object-position: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.wallet-card-item {
    position: relative;
    width: 160px;
    border-radius: 8px;
}

.wallet-card-item .card{
    border-radius: 8px;
    margin-bottom: 30px;
}

@media (min-width: 991px) {
    .wallet-card-item {
        position: relative;
        padding-right: 10px;
    }
}

@media (max-width: 991px) {
.wallet-card-item .card{
 margin-bottom: 20px;
}
}
.restarent-name{
    position: absolute;
    top: 10px;
    right: 15px;
    color: #C33249;
    background-color: #ffffff;
    font-size: 13px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 8px;
}

.wallet-card-deck{
    padding: 10px;
}

.wallet-card-deck .item-name{
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
}
.expiry-main{
    display: flex;
   
    align-items: center;
    margin-bottom: 15px;
}
.restaurant-name{
    position: absolute;
    top: 14px;
    right: 14px;
    color: #000;
    background: #fff;
    font-size: 13px;
    font-weight: 500;
    padding: 4px 10px;
    border-radius: 8px;
    margin-left: 5px;
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 1; /* For compatability with -webkit-line-clamp:*/
    -webkit-line-clamp: 1; /* Adjust based on how many lines you want to display */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.you-save{
    background: rgb(216 229 244);
    border-radius: 12px;
    margin-bottom: 0px;
    line-height: unset;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    padding: 2px 9px;
    margin-right: 5px;
    margin-left: 0px;
}  

.other-tags{
    background: rgb(223, 223, 223);
    border-radius: 12px;
    margin-bottom: 0px;
    line-height: unset;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    padding: 2px 9px;
    margin-right: 5px;
    margin-left: 0px;
}

.expiry-text{
    font-size: 13px;
    margin-bottom: 0px;
}

.card-inner{
    padding: 14px;
}

.location img{
    width: 16px;
    height: 16px;
    object-fit: contain;
}

.location{
    color: #090909;
    font-size: 12px;
    font-weight: 400;
    line-height: unset;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
}

.item-name{
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 6px;
    min-height: 40px;
    max-height: 40px;
    line-height: 1.2; /* Adjust to control the line height */
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 2; /* For compatability with -webkit-line-clamp:*/
    -webkit-line-clamp: 2; /* Adjust based on how many lines you want to display */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
 
}

.wallet-rate-now{
    color: rgb(232, 13, 13);
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 700;
}

.previous-prize{
    font-size: 12px;
    color: #000;
    /* font-weight: 500; */
    /* padding-left: 5px; */
    /* color: rgb(0, 122, 255); */
}

.claim{
    background: rgb(232, 13, 13);
    color: rgb(255, 255, 255);
    border-color: rgb(232, 13, 13);
    position: absolute;
    left: 50%;
    padding: 10px 16px;
    border-radius: 8px;
    bottom: -18px;
    transform: translateX(-50%);

    font-size: 14px;
}   


  
  @media (max-width: 768px) {
  .wallet-card-item {
    display: flex; 
    justify-content: center; 
    align-items: center; 
  }
  .wallet-card-item .card {
    margin-right: 5px;
    width: 160px;
}



.restaurant-name {
    position: absolute;
    top: 10px;
    right: 5px;
    /* color: #C33249; */
    background: #fff;
    font-size: 10px;
    font-weight: 500;
    padding: 4px 7px;
    margin-left: 5px;
    border-radius: 8px;
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 1; /* For compatability with -webkit-line-clamp:*/
    -webkit-line-clamp: 1; /* Adjust based on how many lines you want to display */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    /* width: min-content; */
}


.wallet-card-item img {
   height: 94px;
}
.item-name {
    font-size: 13px;
    min-height: 33px;
    max-height: 33px;
    line-height: 1.2; /* Adjust to control the line height */
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 2; /* For compatability with -webkit-line-clamp:*/
    -webkit-line-clamp: 2; /* Adjust based on how many lines you want to display */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.card-inner {
    padding: 10px;
}

.expiry-main {
    flex-wrap: wrap;
    align-items: baseline;
    margin-bottom: 10px;
}

.you-save {
    font-size: 14px;
    margin-bottom: 5px;
}

.other-tags {
    font-size: 14px;
    margin-bottom: 5px;
}

.expiry-text {
    font-size: 11px !important;
    margin-bottom: 0px;
}

.location img{
    width: 16px;
    height: 16px;
    padding-right: 5px;
}

.location{
    font-size: 10px;
    margin-bottom: 20px;
}

.claim {
    border-radius: 8px;
    width: fit-content;
    padding: 5px 10px;
    position: absolute;
    left: 35%;
    bottom: -10px;
    font-size: 11px;
    transform: unset;
}
  }


  .horizontal-wallets-div{
    margin-left: -5px;
    margin-right: -5px;
    margin-top: 24px;
  
  }

  .horizontal-wallets-div .horizontal-wallets-div .col-lg-3, .horizontal-wallets-div .col-md-6, .horizontal-wallets-div .col-sm-12 {
    padding-left: 3px;
    padding-right: 3px;
  }

  .horizontal-wallets-div .wallet_image{
    width: 100%;
    height: 200px;
    object-fit: cover;
    position: relative;
  }

  .horizontal-wallets-div .merchant-name {
    position: absolute;
    top: 14px;
    right: 14px;
    color: #C33249;
    background: #fff;
    font-size: 13px;
    font-weight: 500;
    padding: 4px 10px;
    border-radius: 8px;
}


@media (max-width: 568px) {
.wallet-rate-now{


   font-size: 22px !important;
   margin-bottom: 5px;


}

.previous-prize{
    font-size: 11px !important;
    margin-bottom: 8px;
}
.horizontal-wallets-div .wallet-rate-now{

    font-size: 22px !important;
    margin-bottom: 10px;
 
 }
 .horizontal-wallets-div .previous-prize{
    font-size: 14px;
}

.horizontal-wallets-div .claim{
    left:36%;
    font-size: 15px;
}


}

.horizontal-wallets-div .card{
    height: 100%;
}

.rest-icon-img-new{
    width: 15px !important;
    height: 15px !important;
    display: inline !important;
}

    @media (max-width: 568px) {
        .rest-icon-img-new{
            width: 13px !important;
            height: 13px !important;
            display: inline !important;
        }
    }

    .you-save small{
        font-weight: 600 !important; 
        font-size: 80% !important;
    }
    .other-tags small{
        font-weight: 600 !important; 
        font-size: 80% !important;
    }
    @media (max-width: 568px) {
    .you-save small {
        font-size: 88% !important;
        font-weight: 600 !important;
    }
    .other-tags small {
        font-size: 88% !important;
        font-weight: 600 !important;
    }
}