/* Footer.css */
.footer {
    background-color: #000;
    padding: 20px 0;
    text-align: center; 
    color: #fff;
    /* Center-align text for the whole footer */
  }
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .footer-logo {
    max-height: 50px; /* Adjust as needed */
  }
  
  @media (max-width: 991px) {
    .footer-content {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    
    .logo {
      margin-bottom: 10px;
    }
  }
  
  