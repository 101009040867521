.wallet-details-wrap {
  padding-top: 1rem;
}

@media screen and (max-width: 991px) {
  .wallet-details-wrap {
    padding-top: 8.5rem;
  }
}


