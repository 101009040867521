.poppins-thin {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: normal;
  }
  
  .poppins-extralight {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: normal;
  }
  
  .poppins-light {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .poppins-regular {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .poppins-medium {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .poppins-semibold {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
  }
  
  .poppins-bold {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .poppins-extrabold {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: normal;
  }
  
  .poppins-black {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: normal;
  }
  
  .poppins-thin-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: italic;
  }
  
  .poppins-extralight-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: italic;
  }
  
  .poppins-light-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: italic;
  }
  
  .poppins-regular-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: italic;
  }
  
  .poppins-medium-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: italic;
  }
  
  .poppins-semibold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: italic;
  }
  
  .poppins-bold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: italic;
  }
  
  .poppins-extrabold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: italic;
  }
  
  .poppins-black-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: italic;
  }
  
 body{
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    overflow-x: hidden;
    width: 100%;
  }

  html{
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    overflow-x: hidden;
    width: 100%;
  }
  .near-by{
    font-size: 30px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    .near-by{
        font-size: 20px;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        margin-top: 20px;
        margin-bottom: 10px;
    }
}

.all-rest{
  font-size: 30px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 20px;;
}

@media (max-width: 768px) {
  .all-rest{
      font-size: 20px;
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      margin-top: 20px;
      margin-bottom: 10px;
  }
}
@media (max-width: 568px) {
p{
  font-size: 14px !important;
}
h2{
  font-size: 20px;

}
h1{
 font-size: 24px;   
}
}
.near-by-item-text{
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-right: 25%; */
}

.near-by-item-text button{
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
  color: rgb(232, 13, 13);
  cursor: pointer;
}
.near-by-item-text button:hover{
text-decoration: none;
}

@media (max-width: 568px) {
  .near-by-item-text{
    margin-right: 25%;
  }
}

.Now-p-tex{
  font-weight: 500;
  font-size: 15px;
}
@media (min-width: 991px) {
  .near-by-item-text button{
    font-size: 18px;
    margin-right: 10px;
 
  }
  .Now-p-tex{
    font-weight: 500;
    font-size: 18px;
  }
}

.all-rest-view-item{
  margin-right: 0px !important;
}

.c-instore-p{
  text-decoration: line-through;
}

.insto-p{
  padding-left: 3px;
  color: #333333 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.in-sp-t{
  text-decoration: line-through;
}

.new-previous-prize{
  color: #333333;
font-size: 14px !important;
}

.location-expire-wrap{
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.location-expire-wrap .expiry-text{
  padding-right: 20px;
  font-size: 14px !important;
}

.new-you-save small {
  font-weight: 600 !important;
  font-size: 87% !important;
}


@media (max-width: 768px) {
.horizontal-coupens-div .item-name {
  font-size: 18px;
}

.new-you-save small {
  font-weight: 600 !important;
  font-size: 100% !important;
}

.horizontal-coupens-div .location {
  font-size: 14px;
  margin-bottom: 20px;
}

.horizontal-coupens-div .restaurant-name {
  font-size: 14px;
 
}

.horizontal-coupens-div .expiry-text {
  font-size: 14px !important;
  margin-bottom: 0px;
}

}

/* solution for the keyboard popping issue  o*/

input:focus {
  outline: none !important;
  box-shadow: none !important;
}

.slick-slider {
  user-select: none !important;
}
