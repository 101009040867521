.section-background {
    background-color: #FBF5EC; 
    padding: 70px 0;
    margin-top: 40px;
  }
  
  .w-80 {
    width: 80%;
  }
  h2{
    font-size: 22px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  
  .item-image {
    max-width: 100px;
    margin-bottom: 10px;
  }
  
  .item-text {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #383A47;
  }
  