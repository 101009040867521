.privacy-policy-container {
  background: white;
}

.privacy-policy-header.full-page {
  background: #e80d0d;
  color: #ffffff;
  width: 100%;
  padding: 2rem 1rem;
  margin-bottom: 1rem;
}

.privacy-policy-header.popup {
  position: absolute;
  top: 0;
  background: #e80d0d;
  color: #ffffff;
  padding: 2rem 1rem;
  margin-bottom: 1rem;
  margin-top: 0px;
}

.privacy-policy-container.full-page {
  width: 100%;
  max-width: none;
  box-shadow: none;
  overflow: auto;
}

.privacy-policy-container.popup {
  width: 100%;
  height: 80vh;
  max-width: none;
  box-shadow: none;
  overflow: auto;
  padding-bottom: 90px;
}

.privacy-policy-content {
  margin-left: 15px;
  margin-right: 15px;
}

.privacy-policy-content.popup {
  margin-left: 15px;
  margin-right: 15px;
  padding-top: 120px;
}

.modal-buttons { 
  position: absolute; 
  bottom: 0; 
  width: 100%; 
  background: white; 
  padding: 1rem; 
  z-index: 1050; 
  display: flex; 
  justify-content: flex-end; 
}
