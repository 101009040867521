h1 {
  font-size: 30px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 20px;
}

.deal-item img {
  width: 100%;
  height: 144px;
  border-radius: 5px;
  object-fit: fill;
}

.top-deals .slick-list {
  padding: 0; /* Remove padding around the slider */
}

.top-deals .slick-track {
  display: flex; /* Ensure flexbox layout */
}

.top-deals .deal-item {
  padding: 0 7px; /* Standard gap between items */
}

.top-deals .slick-slide {
  margin-right: 0; /* Remove default margin */
}

/* Remove left padding on the first item */
.top-deals .slick-slide:first-child {
  padding-left: 0; /* Ensure no extra padding on the first item */
}

/* Mobile-specific adjustments */
@media (max-width: 768px) {
  .top-deals .deal-item {
    padding: 0 3px; /* Further reduce gap between items on mobile */
  }

  .top-deals .slick-slide:nth-child(2n) {
    margin-right: 0 !important; /* Ensure no margin on the last item in each row */
  }
}


.deal-item:focus {
  outline: none;
}

/* Optionally, add a custom focus style */

@media (max-width: 768px) {
h1 {
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 12px;
}
.deal-item img {
  width: 100%;
  height: 92px;
  border-radius: 5px;
  object-fit: fill;
}
}