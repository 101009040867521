.coupen-details-wrap {
  padding-top: 1rem;
}

.coupen-details-wrap .card-body{
    padding: 0px 20px;
}
.coupen-details-wrap .image-card {
  position: relative;
}

.coupen-details-wrap .claim-now-btn-new {
  border-radius: 16px;
  background-color: rgb(232, 13, 13);
  color: rgb(255, 255, 255);
  position: absolute;
  right: 20px;
  top: 20px;
}



.coupen-details-wrap .card{
    max-width: 40%;
    margin: 0 auto;
}

.coupen-details-wrap .card .coupen-image-name{
    height: 250px;
    object-fit: cover;
}

.coupen-details-wrap .coupen-details-inner-div h1{
    text-align: center;
}

.coupen-details-inner-div{
    width: 100%;
}

.coupon-description{
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 7; /* For compatability with -webkit-line-clamp:*/
    -webkit-line-clamp: 7; /* Adjust based on how many lines you want to display */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

@media screen and (max-width:991px){
    .coupen-details-wrap {
        padding-top: 8.5rem;
      } 
  
}
@media screen and (max-width:768px){
.coupen-details-wrap .card{
    max-width: 100%;
    margin: 0 auto;
}
.coupen-details-wrap .coupen-details-inner-div h1{
    text-align: left;
}

.coupen-details-wrap .card .coupen-image-name {
    height: 190px;
    object-fit: cover;
}


}

@media screen and (max-width:768px){
.detail-coupen-btn{
    position: relative;
    top: -8px;
}
}

.coupen-details-wrap .new-rest-name{
    font-size: 14px !important;
}

.coupen-details-wrap .card{
    border: none !important;
}
.c-detail-title{
    font-size: 24px;
}
.coupen-details-wrap .location{
    margin-bottom: 10px !important;
    font-size: 14px;
}

@media screen and (max-width:768px){
    .coupen-details-wrap .new-rest-name{
        font-size: 13px !important;
        margin-right: 8px;
    }
    .coupen-details-wrap .location{
        margin-bottom: 10px !important;
        font-size: 14px;
    }

    .c-detail-title{
        font-size: 20px;
    }
    .coupen-details-wrap .previous-prize{
       font-size: 14px !important;
    }

    .coupen-details-wrap .coupen-rate-now {
        margin-bottom: 12px;
    }
    }

    .coupen-detail-save{
        margin-bottom: 20px !important;
    }

    .coupen-details-wrap .location img {
        width: 25px !important;
        height: 22px !important;
        padding-right: 5px;
    }
   

  