.restaurent-wrapper{
    /* background-color: #FCF7E9; */
    padding: 40px 0px;
    padding-bottom: 0px;
    min-height: calc(100vh - 137px);
}

.restaurent-wrapper .menu-card{

        border: none !important;
        background: #fff;
        padding: 20px;
        border-radius: 8px;
        margin-bottom: 20px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
   
}

.restaurent-wrapper h1{
    margin-top: 0px;
}
.tabbar-wrap{
  padding-bottom: 70px;
  padding-top: 10px;

}

.restaurent-wrapper .rest-img img{ 
    width: 100%;
    height: 160px;
    object-fit: cover;
    border-radius: 8px;
    object-position: center;
}

.rest-img{
    position: relative;
} 

.rest-img .view-all-photos{
    position: absolute;
    bottom: 13px;
    right: 10px;
    background: #fff;
    padding: 5px 12px;
    border-radius: 8px;
    font-size: 13px;
}

.rest-logo img{
    width: 100%;
    height: 160px;
    object-fit: contain;
}

.restaurent-wrapper .tabbar-wrap .nav-item{
    padding-left: 0px;
    border-right: 1px solid #e6e6e6;

  
}


.restaurent-wrapper .nav-tabs .nav-link {
  border: 1px solid #ccc;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
  background-color: #fff;
  margin: 0px !important;
}

.restaurent-wrapper .nav-tabs .nav-link.active {
  color: #495057;

  background: #eef3f8 !important;
  border-color: #fff;
  border: 1px solid #e6e6e6;

}

.restaurent-wrapper .content-container {
    position: relative;
  }
  
  .restaurent-wrapper .content-text {
    margin: 0;
    line-height: 1.5em;
    display: -webkit-box;
    line-clamp: 3; /* For compatability with -webkit-line-clamp:*/
    -webkit-line-clamp: 3; /* Show only 3 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  
  .restaurent-wrapper .toggle-checkbox {
    display: none;
  }
  
  .restaurent-wrapper .toggle-label {
    display: inline-block;
    margin-top: 10px;
    text-decoration: underline;
    color: #EB2D2D;
    font-weight: 500;
    cursor: pointer;
  }
  
  .restaurent-wrapper .toggle-checkbox:checked ~ .content-text {
    line-clamp: unset; /* For compatability with -webkit-line-clamp:*/
    -webkit-line-clamp: unset;
    max-height: none;
  }
  
  .restaurent-wrapper .toggle-checkbox:checked ~ .toggle-label::after {
    content: 'Show Less';
  }
  
  .restaurent-wrapper .toggle-checkbox:not(:checked) ~ .toggle-label::after {
    content: 'Read More';
  }

  .restaurent-wrapper h3{
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
  }
  
  .restaurent-wrapper .rest-location{
    justify-content:flex-start !important;
    font-size: 16px;
  }

  .restaurent-wrapper .img-location{
    width: 30px;
    height: 26px;
    height: 24px;

}

.restaurent-wrapper .about-details{
  margin-top: 24px;
}



@media screen and (max-width:991px) {
  .restaurent-wrapper {
  

    padding-bottom: 0px;
    padding-top: 9rem;
}
.restaurent-wrapper .rest-location {
  justify-content: flex-start !important;
  font-size: 16px;
  flex-wrap: wrap;
}



} 
@media screen and (max-width:568px) {
.restaurent-wrapper .more-info{
  margin-top: 8px;
}
.rest-logo img {

  height: 110px;

}

.restaurent-wrapper .rest-img img {
  width: 100%;
  height:auto;
  object-fit: cover;
  border-radius: 8px;
  object-position: center;
}

  
}

.restaurent-wrapper .about-us-des{
  margin-top: 0px;
}

.deals-container .deal-card {
  /* background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 5px; */
  transition: background-color 0.3s ease, border 0.3s ease;
  height: 100%;
  margin-bottom: 0px;
}

.deals-container .deal-chip {
  background-color: #fff;
  color: #C33249;
  border: 1px solid #C33249;
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 0.9rem;
  margin-bottom: 10px;
  width: fit-content;
}


.deals-container .deal-card:hover{
  background-color: #fffcfc;
  border: 1px solid #C33249 !important;
  transition: background-color 0.3s ease, border 0.3s ease;
}

.deals-container .deal-content {
  padding-right: 20px;
}

.deals-container .deal-content h2 {
 
  margin: 0;
}

.deals-container .deal-content h3 {
  font-size: 14px;
  color: #807e7e;
  font-weight: 400;
padding-top: 3px;
margin-bottom: 4px;
}

.deals-container .deal-content p {
  font-size: 14px;
  color: #807e7e;
  margin-bottom: 0px;
}

.deals-container .btn-primary{
  background: rgb(232, 13, 13);
  color: rgb(255, 255, 255);
  border-color: rgb(232, 13, 13);
  padding: 8px 50px;

}

.deals-container .starting-price{
  font-size: 24px;
  font-weight: 600;
  color: #000;
}
.deals-container .deal-action {
  flex-shrink: 0;
}

.deals-container .card-item-wrap{
  align-items: center;
}

@media (max-width: 767.98px) {
  .deals-container .card-item-wrap{
    align-items: start ;
  }
}
@media (max-width: 767.98px) {
  .deals-container .deal-card .d-flex {
    flex-direction: column;
    text-align: left;
  }

  .deals-container .deal-action {
    margin-top: 15px;
  }
}
