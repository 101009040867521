/* Base styles for the Typeahead input */
.form-control.search-input {
  border: 1px solid #ced4da;  /* Light grey border */
  border-radius: 0.25rem;  /* Rounded corners */
  padding: 0.5rem 0.75rem;  /* Padding inside the input */
  font-size: 1rem;  /* Font size */
  transition: border-color 0.3s ease-in-out;  /* Smooth transition for border color */
}

/* Focus state for the input */
.form-control.search-input:focus {
  border-color: #80bdff;  /* Light blue border on focus */
  outline: none;  /* Remove default outline */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);  /* Light blue shadow */
}

/* Dropdown menu styles */
.rbt-menu.dropdown-menu {
  border: 1px solid #ced4da;  /* Light grey border */
  border-radius: 0.25rem;  /* Rounded corners */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);  /* Light shadow for depth */
}

/* Dropdown item styles */
.rbt-menu .dropdown-item {
  padding: 0.5rem 1rem;  /* Padding for each item */
  font-size: 1rem;  /* Font size */
  color: #495057;  /* Dark grey text */
  cursor: pointer;  /* Pointer cursor */
}

/* Hover state for dropdown items */
.rbt-menu .dropdown-item:hover {
  background-color: #f8f9fa;  /* Light grey background on hover */
  color: #212529;  /* Dark text on hover */
}

/* Selected item styles */
.rbt-menu .dropdown-item.active, 
.rbt-menu .dropdown-item:active {
  background-color: #007bff;  /* Blue background for active item */
  color: #fff;  /* White text for active item */
}

/* Optional: Adding custom styles for the input placeholder */
.form-control.search-input::placeholder {
  color: #6c757d;  /* Medium grey for placeholder text */
  opacity: 1;  /* Ensure opacity is set to 1 for better visibility */

}

/* Base navbar styles */
.navbar {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-height: 81px;
  }

  .navbar-light .navbar-toggler {
    border-color: rgba(0, 0, 0, 0.9);
  }
  
  /* Styles for web view */
   .search-bar-web {
    display: flex;
    align-items: center;
    margin-left: 20px;
  }
  
  .input-group {
    border: 1px solid #ccc;
    border-radius: 100px;
    background: none;
  }
.input-group-text {
    background: unset;
    border: unset;
  }
  
  .search-input {
    background-color: unset;
    border: none;
    padding-left: 0px;
    padding: 0px;
  }
  
    
  .nav-mbl-wrapper .form-control {
    border: none;
    background-color: unset;
    padding-left: 0px !important;
  }
  
  .input-group-append, .input-group-prepend {
    display: flex;
    background: none;
  }
  
  .login {
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 100px;
    color: #000;
    padding: 10px 20px;
  }
  
  .signup {
    background-color: #C33249;
    border-radius: 100px;
    color: #f7ebeb;
    padding: 10px 20px;
    border: none;
  }
  
  .nav-item {
    padding-left: 16px;
    color: #000;
  }
  
  .navbar-nav .nav-link {
    color: rgba(0, 0, 0, .5);
  }
  .nav-mbl-wrapper{
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        min-height: 81px;
  }
  
  @media (max-width: 991px) {
    .navbar {
      position: relative;
      box-shadow: unset;
      min-height: 55px;
    }

.nav-mbl-wrapper{
    background-color: #fff;
    width: 100%;
    position: fixed;
    z-index: 10000;
    top: 0;
    min-height:55px;
}

    .search-box-container{
        padding: 0px 15px 15px 15px;
    }
  
    /* .search-bar-mobile {
      display: none;
    } */
  
    .search-icon-container {
      display: flex;
      align-items: center;
      margin-right: 20px;
    }
/*   
    .search-icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
    } */
  
    .search-bar-overlay {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.9);
      z-index: 1020;
      justify-content: center;
      align-items: center;
      padding: 20px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  
    .search-bar-overlay .input-group {
      width: 100%;
    }
  
    .search-bar-mobile.show {
      display: flex;
    }
  
    .search-icon-container:hover + .search-bar-overlay,
    .search-bar-overlay:hover {
      display: flex;
    }
  }
  
  @media (max-width: 991px) {
  /* .search-icon{
    display: none;
  } */
.navbar-brand img{
  width: 120px;
}

}


@media (min-width: 991px) {
.input-group {
  border: 1px solid #ccc;
  border-radius: 100px;
  background: none;
  width: 309px !important;
}

}


@media (min-width: 1300px) {
  .input-group {
    border: 1px solid #ccc;
    border-radius: 100px;
    background: none;
    width: 380px !important;
  }
  
  }

  @media (max-width: 1199px) {
    .navbar-nav {
      padding-top: 7px;
    }
  }

  @media (min-width: 1200px) {
    .navbar-expand-xl .navbar-nav {
        flex-direction: row;
        align-items: center; 
    }

    .search-icon{
      margin-left: 1rem;
    }

    .wallet-icon-small{
      display: none !important;
    }
}

@media (max-width: 1200px) {
.nav-item-row{
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    margin-left: auto; /* This aligns the element to the right */
    display: block;
}

.wallet-icon-large{
  display: none !important;
}
}

.coupen-basket-logo{
  width: 160px;
  height:62px;
  object-fit: cover;
}

.Nav-bar-logo-section{
  width: 150px;
}

.scrolling-placeholder {
  position: relative;
  width: 100%; /* Adjust width as needed */
  padding-left: 10px; /* Optional: adjust padding for spacing */
  white-space: nowrap; /* Prevent placeholder text from wrapping */
  overflow: hidden; /* Hide overflow text */
  border: 1px solid #ccc; /* Optional: for visual boundary */
}

.scrolling-placeholder::placeholder {
  display: inline-block;
  position: absolute;
  left: 100%;
  animation: scroll-placeholder 10s linear infinite;
}

@keyframes scroll-placeholder {
  from {
      transform: translateX(100%);
  }
  to {
      transform: translateX(-100%);
  }
}
@media (min-width: 991px) {
.search-box-container{
  z-index: 100000;
  position: fixed;
  top: 20px;
  left: 17%;
}
}