/* Ensure no padding or border on slider container */
.allmerch .slider-container {
    padding: 0px;
    margin: 0px;
}

/* Card styling */
.allmerch .merchant-card {
    margin-right: 10px;
    border-radius: 8px;
}

.merchant-card .card-logo{
    width: 100%;
    object-fit: cover;
    height: 150px;
    object-position: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.card{
    border: 1px solid #E6E6E6 !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.card-text{
    margin-bottom: 1px;
}

.allmerch .merchant-card .card-center{
    min-height: 50px;
    max-height: 50px;
    line-height: 1.2; /* Adjust to control the line height */
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 3; /* For compatability with -webkit-line-clamp:*/
    -webkit-line-clamp: 3; /* Adjust based on how many lines you want to display */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.allmerch .merchant-card .card-location{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3px;
}
.offer-count{
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 3px;
}
.view-offer{
    text-decoration: underline;
    color: #EB2D2D;
    font-weight: 500;
}

.allmerch .slick-slider-inner{
    padding: 70px 0px;
}


  
  @media (max-width: 768px) {
    .allmerch .slider-item{
    width: 129%;
    }
  }



  .allmerch .slick-slider-container {
    position: relative !important;
    padding-bottom: 70px;
}

.allmerch .slick-prev, .allmerch .slick-next {
    width: 30px !important; /* Adjust the size of your arrows */
    height: 30px !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    position: absolute !important;
    bottom: 0px !important; /* Position them at the bottom */
    z-index: 2 !important;
    cursor: pointer !important;
    transform: unset !important;
    top: unset !important;
}

/* To align both arrows at the bottom right corner */
.allmerch .slick-prev {
    right: 40px !important; /* Create space for the next arrow */
    background-image: url('../components/images/arrow-left.svg') !important;
    left: unset !important;
}

.allmerch .slick-next {
    right: 0% !important; /* Place the next arrow closer to the previous one */
    background-image: url('../components/images/arrow-right.svg') !important;
}

.allmerch .slick-prev:before, .slick-next:before {
    content: '' !important; /* Remove the default arrows */
}

.allmerch .card-title {
    margin-bottom: .75rem;
    font-size: 15px;
    min-height: 77px;
    max-height: 77px;
    line-height: 1.2; /* Adjust to control the line height */
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 4; /* For compatability with -webkit-line-clamp:*/
    -webkit-line-clamp: 4; /* Adjust based on how many lines you want to display */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

@media (max-width: 568px) {

    .allmerch .slick-slider-container {
        position: relative !important;
        padding-bottom: 50px;
        width: 129%;
    }
    
    .allmerch .slick-next {
    right: 24% !important;

}

.allmerch .merchant-card .card-logo {
    height: 94px;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.allmerch h1{
    margin-top: 30px;
}
.allmerch .view-offer {
    color: #EB2D2D;
    font-weight: 500;
    font-size: 13px;
}

.allmerch .card-title {
    font-size: 14px;
    min-height: 52px;
    max-height: 52px;
    line-height: 1.2; /* Adjust to control the line height */
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 3; /* For compatability with -webkit-line-clamp:*/
    -webkit-line-clamp: 3; /* Adjust based on how many lines you want to display */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }

.allmerch .card-body{
    padding: 10px;
}

.allmerch .slick-prev {
    right: 33% !important; /* Create space for the next arrow */
  
}

.allmerch {
    padding-bottom: 20px;
}

.allmerch .merchant-card {
    margin-right: 10px;
    width: 97% !important;
}
}