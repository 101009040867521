
/* .carousel .carousel-indicators button.active {
    background-color: #fffcfc;
}

.carousel .carousel-indicators button {
    background-color: #9e9c9c;
    margin: 5px;
    border: none;
    width: 10px;
    height: 2px;
} */


/* BannerSlider.css */
.banner-slider {
    width: 100%;
  }
  
  .banner-slider .slick-slide img {
    width: 100%;
    height: 412px;
    object-fit: cover; /* Ensures the image covers the entire slide area */
  }
  
  .banner-slider .slick-dots li button:before {
    font-size: 12px;
    color: red; /* Set the dot color to red */
  }
  
  .banner-slider .slick-dots li.slick-active button:before {
    color: red; /* Ensure active dot is also red */
  }
  

  .banner-slider .slick-dots {
    bottom: 20px;
  }

   @media (max-width: 768px) {
    .banner-slider .slick-slide img {
        height: 100%;
    }
   }

   @media (max-width: 991px) {
    .banner-slider {
      padding-top: 7.8rem;
    }
   }